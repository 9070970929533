import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiFillHome } from "react-icons/ai";
import { PiArrowLeftBold } from "react-icons/pi";
import { Link, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import { dataDecrypt } from '../app/utils/encryption-helper';
import Collapsible from 'react-collapsible';
import { useSelector } from 'react-redux';
import LoginOtp from './LoginOtp';
import LOGO from './../assets/images/vyapar_logo.svg';
import { ApisService } from '../app/core/apis.service';

export const Payment_Reconciliation = () => {
  const [PaymentData, setPaymentData] = useState<any>([]);
  const userData = useSelector((state: any) => state);
  const routerParams = useParams();
  //time 
  function formatTimestamp(timestamp: number): string {
    const dateObj = new Date(timestamp);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const hours = dateObj.getHours();
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    return `${year}-${month}-${day} `;
  }
  const created_date: string = formatTimestamp(PaymentData[0]?.created_date);
  console.log(created_date);

  useEffect(() => {
    loadDelivery();
  }, []);

  async function loadDelivery(): Promise<void> {
    console.log('routerParams ', routerParams);

    try {
      // const res = await axios.post('http://65.1.45.201:8089/api/v1/http-utils/grpc', {
      //   endpoint: 'transaction.65.1.45.201:8089',
      //   method: 'finInvoiceById',
      //   body: routerParams.id,
      // });
      const res = await ApisService.getRetailData('findInvoiceById', routerParams.id as string)

      console.log(res);

      const data: any = res.data.data;
      console.log(data)
      const transactionData = JSON.parse(dataDecrypt(data));
      setPaymentData([...transactionData]);
      console.log(transactionData);
    } catch (error) {
      console.error('Error in transaction:', error);
    }
  }
  // console.log(PaymentData[0]?.invoice_details?.actual_delivery_date);
  const Invoicedata = () => {
    const invoiceDetails = PaymentData[0]?.invoice_details;
    // console.log(invoiceDetails);
    const invoiceElements = [];

    const invoicedata = invoiceDetails;
    const invoice = invoicedata?.bill_no;
    const resolution = invoicedata?.payment_reconciliation?.status;
    const dueDate = formatTimestamp(invoicedata?.payment_due_date);
    const pending = invoicedata?.pending_amount;
    if (invoicedata?.payment_reconciliation?.status === "resolved") {
      const invoiceElement = (
        <div key={invoice} className='mt-2'>
          <Collapsible trigger={<Card>
            <Card.Body style={{ lineHeight: '1' }}>
              <h6 style={{ color: "#274472" }}>Invoice #{invoice}</h6>
              <b>Resolution</b><span >: {resolution}</span>
            </Card.Body>
          </Card>}>
            <div className='card'>
              <div className='cardbody' style={{ color: "#274472" }}>
                <div className='row mt-2'>
                  <div className='col-6'>
                    <div className='row'>
                      <div className='col-6'>
                        <b >Invoice Amount</b>
                      </div>
                      <div className='col-6'>
                        :  {invoicedata?.invoice_amount}
                      </div>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='row'>
                      <div className='col-6'>
                        <b>Payment Due Date</b>
                      </div>
                      <div className='col-6'>
                        :{dueDate}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-6'>
                    <div className='row'>
                      <div className='col-6'>
                        <b >Part Payment</b>
                      </div>
                      <div className='col-6'>
                        :
                      </div>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='row'>
                      <div className='col-6'>
                        <b>Part Payment Date</b>
                      </div>
                      <div className='col-6'>
                        :
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-6'>
                    <b>Resolution</b> : {invoicedata?.payment_reconciliation?.status}

                  </div>
                  <div className='col-6'>
                    <button>Go to Reconciliation Discussion</button>
                  </div>
                </div>
                <div className=' mt-2'>
                  <b>Pending Amount</b> : {pending}
                </div>
                <div className=' mt-2'>
                  <b>Payment Sent</b> :
                </div>
                <div className='card m-3 p-2 bg-light '>
                  <div className='cardbody'>
                    <div className='row'>
                      <div className='col-6'>{invoicedata?.payment_received[0]?.received_date}</div>
                      <div className='col-6'>{invoicedata?.invoice_amount}</div>

                    </div>
                  </div>
                </div>

              </div>
            </div>

          </Collapsible>

        </div>
      )
      invoiceElements.push(invoiceElement);

    }

    return <div>{invoiceElements}</div>
  }
  return (
    <div>
      {/* <div> */}
      {(!userData || !userData?.UserDataReducer || !userData?.UserDataReducer?.otp) && <LoginOtp reference=''/>}
      {userData?.UserDataReducer?.otp && <>
        <div className='d-flex justify-content-between p-3' style={{ backgroundColor: "#274472", color: "white" }}>
          <Link to="/order/:id"> <span ><PiArrowLeftBold size={22} className='text-white' /></span></Link>
          <span style={{ lineHeight: "0", textAlign: "center" }}><h4>Payment Reconciliation</h4><div>{PaymentData[0]?.buyer?.name}</div></span>
          {/* <span ><AiFillHome size={23} className='text-white' /></span> */}<span></span>
        </div>
        <br />
        <div className='container-fluid'>
          <div className='rounded'>
            <div className='card mb-3 p-2'>
              <div className='card.body'>
                <div className='row'>
                  <h6 className='col-4'>Name</h6>
                  <span className='col-8'>: {PaymentData[0]?.seller?.name}(Seller)</span>
                </div>

                <div className='row'>
                  <h6 className='col-4'>Txn Id</h6>
                  <span className='col-8'>: </span>
                  {/* {PaymentData[0]?.id} */}
                </div>

                <div className='row'>
                  <h6 className='col-4'>Txn Initiated Date</h6>
                  <div className='col-8'>: </div>
                  {/* {created_date} */}
                </div>
              </div>
            </div>
          </div>


          {Invoicedata()}
        </div>
      </>}
      {/* </div> */}
    </div>
  )

}
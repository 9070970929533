import axios from 'axios';
import { AppConfig } from './../config';
export class ApisService {
    public static async getRetailData(method: string, body: string): Promise<any> {
        return axios.post(`${AppConfig.BASE_URL}/api/v1/http-utils/grpc`, {
            endpoint: `retail.${AppConfig.BASE_URL}`,
            method, body
        });
    }

    public static async getTransactionData(method: string, body: string): Promise<any> {
        return axios.post(`${AppConfig.BASE_URL}/api/v1/http-utils/grpc`, {
            endpoint: `transaction.${AppConfig.BASE_URL}`,
            method, body
        });
    }

    public static async getUserData(method: string, body: string): Promise<any> {
        return axios.post(`${AppConfig.BASE_URL}/api/v1/http-utils/grpc`, {
            endpoint: `users.${AppConfig.BASE_URL}`,
            method, body
        });
    }

    public static async verifyOTP(otp: number, reference: string): Promise<any> {
        return axios.post(`${AppConfig.BASE_URL}/notification/verify-otp`, { otp, reference });
    }

    public static async getProductData(method: string, body: string): Promise<any> {
        return axios.post(`${AppConfig.BASE_URL}/api/v1/http-utils/grpc`, {
            endpoint: `products.${AppConfig.BASE_URL}`,
            method, body
        });
    }

    public static async getBuDetailsData(method: string, body: string): Promise<any> {
        return axios.post(`${AppConfig.BASE_URL}/api/v1/http-utils/grpc`, {
            endpoint: `organization.${AppConfig.BASE_URL}`,
            method, body
        });
    }

    //time 
    public static formatTimestamp(timestamp: string): string {
        const dateObj = new Date(timestamp);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        const hours = dateObj.getHours();
        const minutes = String(dateObj.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
    }
}
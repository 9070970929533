import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiFillHome } from "react-icons/ai";
import { PiArrowLeftBold } from "react-icons/pi";
import { Link, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import { dataDecrypt, dataEncrypt } from '../app/utils/encryption-helper';
import LOGO from './../assets/images/vyapar_logo.svg';
import { ApisService } from '../app/core/apis.service';
import { DownloadInfo } from './download-info';



export const Transaction = () => {
  const [transactionData, setTransactionData] = useState<any>([]);
  const [transactionPackingData, setTransactionPackingData] = useState<any>();
  const [transactionDeliveryData, setTransactionDeliveryData] = useState<any>();
  const [transactionStockReconciliationData, setTransactionStockReconciliationData] = useState<any>();
  const [transactionPaymentReconciliationData, setTransactionPaymentReconciliationData] = useState<any>();
  const [showAppDownload, setShowAppDownload] = useState(false);
  const [showReceivePopup, setShowReceivePopup] = useState(false);

  useEffect(() => {
    if (transactionData[0]) {
      updatePackingData();
      updateDeliveryTracking();
      updateStockReconciliation();
      updatePaymentReconciliation();
    }
  }, [transactionData]);
  const routerParams = useParams();

  const created_date: string = ApisService.formatTimestamp(transactionData[0]?.created_date);

  type TransactionStatus = {
    [x: string]: any;
    Status: string,

  };
  const transactionStatusList: TransactionStatus[] = [];
  console.log(transactionStatusList)

  useEffect(() => {
    loadTransaction();
  }, []);

  async function loadTransaction(): Promise<void> {
    console.log('routerParams ', routerParams);

    try {
      const res = await ApisService.getTransactionData('getById_v2', routerParams.id || '');
      const data: any = res.data.data;
      const transactionData = JSON.parse(dataDecrypt(data));
      setTransactionData([...transactionData]);

    } catch (error) {
      console.error('Error in transaction:', error);
    }
  }

  const PACKING_COMPLETED: string = "Packing completed";
  const READY_FOR_DELIVERY: string = "Ready for delivery";
  const STOCK_NOT_RESOLVED: string = "Stock not resolved";
  const STOCK_RESOLVED: string = "Stock resolved";
  const PAYMENT_NOT_RESOLVED: string = "Payment not resolved";
  const PAYMENT_RESOLVED: string = "Payment resolved";
  const CTR1: string = "CTR1";
  const CTR3: string = "CTR3";
  const CANCELLED: string = "cancelled";

  interface TransactionItem {
    name: string;
    color: string;
    [x: string]: any;
  }

  const updatePackingData = () => {
    //Packing & Inspections
    if (transactionData[0].invoice_details?.length > 0) {
      let isPendingDetailsUpdated = false;
      let packingCompletedInvoices = 0;
      let packingPendingInvoices = 0;
      let packingCancelledInvoices = 0;

      for (let i = 0; i < transactionData[0].invoice_details.length; i++) {
        if (transactionData[0].invoice_details[i].status === CANCELLED) {
          packingCancelledInvoices = packingCancelledInvoices + 1;
          packingCompletedInvoices = packingCompletedInvoices + 1;
        }
        else if (transactionData[0].invoice_details[i].package_details != null) {

          isPendingDetailsUpdated = true;
          packingCompletedInvoices = packingCompletedInvoices + 1;
        }
        else {
          packingPendingInvoices = packingPendingInvoices + 1;
        }
      }
      if (isPendingDetailsUpdated) {

        const packingobj1 = {
          status: PACKING_COMPLETED,
          packingCompletedInvoices: packingCompletedInvoices === 0 ? "0" : packingCompletedInvoices,
          packingPendingInvoices: packingPendingInvoices === 0 ? "0" : packingPendingInvoices,
          packingCancelledInvoices: packingCancelledInvoices === 0 ? "0" : packingCancelledInvoices
        }
        setTransactionPackingData(packingobj1);
      } else {
        const packingobj2 = {
          status: "Packing Not Completed",
          packingCompletedInvoices: packingCompletedInvoices,
          packingPendingInvoices: packingPendingInvoices,
          packingCancelledInvoices: packingCancelledInvoices
        };
        setTransactionPackingData(packingobj2);
      }
    }
  }

  const updateDeliveryTracking = () => {
    if (transactionData[0].invoice_details?.length > 0) {
      let deliveryCompletedInvoices = 0;
      let deliveryPendingInvoices = 0;
      let isDeliveryDetailsUpdated = false;
      let deliveryCancelledInvoices = 0;

      if ('ctr' in transactionData[0] && transactionData[0].ctr != null && transactionData[0].ctr.type === CTR1) {
        const indexIndirectData = transactionData[0].invoice_details[0];
        //console.log(indexIndirectData);
        if (indexIndirectData.status === CANCELLED) {
          deliveryCancelledInvoices = deliveryCancelledInvoices + 1;
          deliveryCompletedInvoices = deliveryCompletedInvoices + 1;
        }
        else {
          if ('invoice_type' in indexIndirectData && indexIndirectData.invoice_type === CTR3 && indexIndirectData.ctrTransactionDetails != null) {
            if (indexIndirectData.stock_received_status === 'received') {
              isDeliveryDetailsUpdated = true;
              deliveryCompletedInvoices = deliveryCompletedInvoices + 1;
            } else {
              isDeliveryDetailsUpdated = true;
              deliveryPendingInvoices = deliveryPendingInvoices + 1;
            }
          }
        }
      }
      if (isDeliveryDetailsUpdated) {
        const DeliveryObj1 = {
          status: "Delivery Details updated",
          deliveryCompletedInvoices: deliveryCompletedInvoices,
          deliveryPendingInvoices: deliveryPendingInvoices,
          deliveryCancelledInvoices: deliveryCancelledInvoices
        }
        setTransactionDeliveryData(DeliveryObj1);
      }
      // console.log(data[0].invoice_details.length);
      for (let i = 0; i < transactionData[0].invoice_details.length; i++) {
        //console.log(data[0].invoice_details[i]);
        const indexInvoiceDeliveryData = transactionData[0].invoice_details[i];
        if (indexInvoiceDeliveryData.package_details != null) {
          // console.log(indexInvoiceDeliveryData.package_details != null)
          if ('invoice_type' in indexInvoiceDeliveryData && indexInvoiceDeliveryData.invoice_type === CTR3) {
            if (indexInvoiceDeliveryData.stock_received_status === 'received') {
              isDeliveryDetailsUpdated = true;
              deliveryCompletedInvoices = deliveryCompletedInvoices + 1;
            }
            else if ('ctrTransactionDetails' in indexInvoiceDeliveryData && indexInvoiceDeliveryData.ctrTransactionDetails != null) {
              isDeliveryDetailsUpdated = true;
              deliveryPendingInvoices = deliveryPendingInvoices + 1;
            }
            else {
              deliveryPendingInvoices = deliveryPendingInvoices + 1;
            }
          }
          else {
            if (indexInvoiceDeliveryData.order_track != null) {
              // console.log(indexInvoiceDeliveryData.order_track != null)
              if (indexInvoiceDeliveryData.status === CANCELLED) {
                isDeliveryDetailsUpdated = true;
                deliveryCancelledInvoices = deliveryCancelledInvoices + 1;
                deliveryCompletedInvoices = deliveryCompletedInvoices + 1;
              }
              else if (indexInvoiceDeliveryData.stock_received_status === "received") {
                isDeliveryDetailsUpdated = true;
                deliveryCompletedInvoices = deliveryCompletedInvoices + 1;
              }
              else {
                isDeliveryDetailsUpdated = true;
                deliveryPendingInvoices = deliveryPendingInvoices + 1;
              }
            }
            else {
              deliveryPendingInvoices = deliveryPendingInvoices + 1;
            }
          }
        }
      }
      if (isDeliveryDetailsUpdated) {
        const DeliveryObj1 = {
          status: "Delivery Details updated",
          deliveryCompletedInvoices: deliveryCompletedInvoices === 0 ? "0" : deliveryCompletedInvoices,
          deliveryPendingInvoices: deliveryPendingInvoices === 0 ? "0" : deliveryPendingInvoices,
          deliveryCancelledInvoices: deliveryCancelledInvoices === 0 ? "0" : deliveryCancelledInvoices,
        }
        setTransactionDeliveryData(DeliveryObj1);
      }
      else {
        const DeliveryObj2 = {
          status: "Delivery Details not updated",
          deliveryCompletedInvoices: deliveryCompletedInvoices === 0 ? "0" : deliveryCompletedInvoices,
          deliveryPendingInvoices: deliveryPendingInvoices === 0 ? "0" : deliveryPendingInvoices,
          deliveryCancelledInvoices: deliveryCancelledInvoices === 0 ? "0" : deliveryCancelledInvoices,
        }
        setTransactionDeliveryData(DeliveryObj2);
      }
    }
  }

  const updateStockReconciliation = () => {
    //Stock Reconciliation
    if (transactionData[0].invoice_details?.length > 0) {
      let stockResolvedInvoices = 0;
      let stockNotResolvedInvoices = 0;
      let stockcancelledInvoices = 0;
      let stockPendingInvoice = 0;
      let isStockResoved = false;
      let totalCompletedStock = 0;
      for (let i = 0; i < transactionData[0].invoice_details.length; i++) {
        const indexInvoiceData = transactionData[0].invoice_details[i];
        if (transactionData[0].status !== READY_FOR_DELIVERY && indexInvoiceData.stock_reconciliation != null) {
          const indexData = indexInvoiceData.stock_reconciliation;
          if (indexInvoiceData.status === CANCELLED) {
            stockcancelledInvoices = stockcancelledInvoices + 1;
            totalCompletedStock = totalCompletedStock + 1;
          }
          else if (indexData.status === "resolved") {
            stockResolvedInvoices = stockResolvedInvoices + 1;
            totalCompletedStock = totalCompletedStock + 1;
            isStockResoved = true;
          }
          else if (indexData.status === STOCK_NOT_RESOLVED ||
            indexData.status === "not resolved") {
            stockNotResolvedInvoices = stockNotResolvedInvoices + 1;
            totalCompletedStock = totalCompletedStock + 1;
          }
          else if (indexInvoiceData.stock_received_status === 'received') {
            totalCompletedStock = totalCompletedStock + 1;
            isStockResoved = true;
          }
          else if (indexData.status === "pending") {
            stockPendingInvoice = stockPendingInvoice + 1;
          }
        }


        if (isStockResoved) {
          const StockObj1 = {
            status: STOCK_RESOLVED,
            stockResolvedInvoices: stockResolvedInvoices === 0 ? "0" : stockResolvedInvoices,
            stockNotResolvedInvoices: stockNotResolvedInvoices === 0 ? "0" : stockNotResolvedInvoices,
            stockcancelledInvoices: stockcancelledInvoices === 0 ? "0" : stockcancelledInvoices,
            stockPendingInvoice: stockPendingInvoice === 0 ? "0" : stockPendingInvoice,
            totalCompletedStock: totalCompletedStock === 0 ? "0" : totalCompletedStock
          }
          setTransactionStockReconciliationData(StockObj1);

        }
        else {
          const StockObj2 = {
            status: STOCK_NOT_RESOLVED,
            stockResolvedInvoices: stockResolvedInvoices === 0 ? "0" : stockResolvedInvoices,
            stockNotResolvedInvoices: stockNotResolvedInvoices === 0 ? "0" : stockNotResolvedInvoices,
            stockcancelledInvoices: stockcancelledInvoices === 0 ? "0" : stockcancelledInvoices,
            stockPendingInvoice: stockPendingInvoice === 0 ? "0" : stockPendingInvoice,
            totalCompletedStock: totalCompletedStock === 0 ? "0" : totalCompletedStock
          }
          setTransactionStockReconciliationData(StockObj2);
        }
      }
    }
  }

  const updatePaymentReconciliation = () => {

    //Payment Reconciliation         
    if (transactionData[0].invoice_details?.length > 0) {
      let paymentResolved = 0;
      let paymentNotResolved = 0;
      let isPaymentResolved = false;
      let paymentPending = 0;
      let totalCompletedPayment = 0;
      for (let i = 0; i < transactionData[0].invoice_details.length; i++) {
        let isStockResolved = false;
        const indexStockData = transactionData[0].invoice_details[i].stock_reconciliation;
        const indexPaymentData = transactionData[0].invoice_details[i].payment_reconciliation;
        if (indexStockData != null && indexStockData.status === "resolved") {
          isStockResolved = true;
        }
        if (isStockResolved) {
          if (indexPaymentData != null) {
            if (indexPaymentData.status === "resolved") {
              paymentResolved = paymentResolved + 1;
              totalCompletedPayment = totalCompletedPayment + 1;
              isPaymentResolved = true
            }
            else if (indexPaymentData.status === "not resolved") {
              paymentNotResolved = paymentNotResolved + 1;
              totalCompletedPayment = totalCompletedPayment + 1;
            }
            else if (indexPaymentData.status === 'pending') {
              paymentPending = paymentPending + 1;
            }
          }
        }
      }

      if (isPaymentResolved) {
        const paymentobj1 = {
          status: PAYMENT_RESOLVED,

          paymentResolved: paymentResolved === 0 ? "0" : paymentResolved,
          totalCompletedPayment: totalCompletedPayment === 0 ? "0" : totalCompletedPayment,
          paymentPending: paymentPending === 0 ? "0" : paymentPending,
          paymentNotResolved: paymentNotResolved === 0 ? "0" : paymentNotResolved
        }
        setTransactionPaymentReconciliationData(paymentobj1);
      }
      else {
        const paymentobj2 = {
          status: PAYMENT_NOT_RESOLVED,
          paymentResolved: paymentResolved === 0 ? "0" : paymentResolved,
          totalCompletedPayment: totalCompletedPayment === 0 ? "0" : totalCompletedPayment,
          paymentPending: paymentPending === 0 ? "0" : paymentPending,
          paymentNotResolved: paymentNotResolved === 0 ? "0" : paymentNotResolved
        }
        setTransactionPaymentReconciliationData(paymentobj2);
      }
    }
  }

  const openDownloadPopup = () => {
    setShowAppDownload(true);
  }

  return (
    <div>
      <DownloadInfo show={showAppDownload} close={setShowAppDownload}></DownloadInfo>
      <div className='d-flex justify-content-center  p-3 position-relative' style={{ backgroundColor: "#274472", color: "white" }}>
        {/* <span ><PiArrowLeftBold size={22} className='text-white' /></span> */}
        <h4>Transactions</h4>
        <img className='position-absolute end-0 top-0' src={LOGO} alt="" height={60} />
        {/* <span ><AiFillHome size={23} className='text-white' /></span> */}
      </div>
      <br />
      {transactionData[0] && <div className='container-fluid'>
        <div style={{ backgroundColor: "white", borderRadius: "3px" }}>
          <Card>
            <Card.Body>
              <div className='row'>
                <h6 className='col-4 '>Name</h6>
                <span className='col-8 '>: {transactionData[0]?.seller?.name}  (Seller)</span>

              </div>
              <div className='row'>
                <h6 className='col-4'>Txn Initiated Date</h6>
                <span className='col-8'>: {created_date}</span>
              </div>
            </Card.Body>
          </Card>
        </div>
        <br />
        <div>
          <Card className='mb-3' style={{ border: `2px solid #8f00f8` }}>
            <Card.Body >
              <div><b>Order Discussions</b></div>
              <i>Not applicable</i>
            </Card.Body>
          </Card>
          <Card className='mb-3' style={{ border: `2px solid #8f00f8` }}>
            <Card.Body >
              <div><b>Confirmation Message</b></div>
              <i>Not applicable</i>
            </Card.Body>
          </Card>
          <Card className='mb-3' style={{ border: `2px solid #8f00f8` }}>
            <Card.Body >
              <div><b>Po(Purchase Order)</b></div>
              <i>Not applicable</i>
            </Card.Body>
          </Card>
          <Card className='mb-3' style={{ border: `2px solid #8f00f8` }}>
            <Card.Body >
              <Link to={`/invoice/${routerParams.id}`} style={{ textDecoration: 'none', color: '#000000' }}>
                <div><b>Invoice</b></div>
                <div>Status: {transactionData[0]?.invoice_details && transactionData[0]?.invoice_details[0]?.status}</div>
                <div>Number of Invoices: {transactionData[0]?.invoice_details?.length}</div>
                <div>Cancelled Invoices: {transactionData[0]?.invoice_details?.filter((el: any) => el.status === 'cancelled').length}</div>
              </Link>
            </Card.Body>
          </Card>
          <Card className='mb-3' style={{ border: `2px solid #8f00f8` }}>
            <Card.Body >
              <Link to={`/packing-details/${routerParams.id}`} style={{ textDecoration: 'none', color: '#000000' }}>
                <div><b>Packing & Inspections</b></div>
                <div>Status: {transactionPackingData?.status}</div>
                {/* <div>Number of Invoices: {transactionData[0]?.invoice_details?.length}</div>
                <div>Cancelled Invoices: {transactionData[0]?.invoice_details?.filter((el: any) => el.status === 'cancelled').length}</div> */}
              </Link>
            </Card.Body>
          </Card>
          <Card className='mb-3' style={{ border: `2px solid #8f00f8` }}>
            <Card.Body >
              <Link to={`/delivery-details/${routerParams.id}`} style={{ textDecoration: 'none', color: '#000000' }}>
                <div><b>Delivery Tracking</b></div>
                <div>Status: {transactionDeliveryData?.status}</div>
                {/* <div>Number of Invoices: {transactionData[0]?.invoice_details?.length}</div>
              <div>Cancelled Invoices: {transactionData[0]?.invoice_details?.filter((el: any) => el.status === 'cancelled').length}</div> */}
              </Link>
            </Card.Body>
          </Card>
          <Card className='mb-3' style={{ border: `2px solid #8f00f8` }}>
            <Card.Body >
              <Link to={`/stock-details/${routerParams.id}`} style={{ textDecoration: 'none', color: '#000000' }}>
                <div><b>Stock Reconciliation</b></div>
                <div>Status: {transactionStockReconciliationData?.status}</div>
                {/* <div>Number of Invoices: {transactionData[0]?.invoice_details?.length}</div>
              <div>Cancelled Invoices: {transactionData[0]?.invoice_details?.filter((el: any) => el.status === 'cancelled').length}</div> */}
              </Link>
            </Card.Body>
          </Card>
          <Card className='mb-3' style={{ border: `2px solid #8f00f8` }}>
            <Card.Body  onClick={openDownloadPopup} style={{cursor: 'pointer'}}>
              <div><b>Payment Reconciliation</b></div>
              <div>Status: {transactionPaymentReconciliationData?.status}</div>
              {/* <div>Number of Invoices: {transactionData[0]?.invoice_details?.length}</div>
              <div>Cancelled Invoices: {transactionData[0]?.invoice_details?.filter((el: any) => el.status === 'cancelled').length}</div> */}
            </Card.Body>
          </Card>
        </div>
      </div>}
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import SuccessGif from './../assets/images/Animation - 1706521632571.gif';
import { Link, useNavigate } from 'react-router-dom';
import { PiArrowLeftBold } from "react-icons/pi";
import LOGO from './../assets/images/vyapar_logo.png';
import { ApisService } from '../app/core/apis.service';
import { dataDecrypt, dataEncrypt } from '../app/utils/encryption-helper';
import { connect } from 'react-redux';
import { UserData } from '../app/store/model/userdata';

interface Props {
    userState: UserData
}
const SuccessOrder = (props: Props) => {
    const navigation = useNavigate();

    const onClickNewOrder = () => {
        const id = dataEncrypt(JSON.stringify({ id: props.userState.bu_data._id }));
        navigation(`/order-form/${id}`)
    }

    return (
        <div className='align-items-center'>
            <div className='d-flex flex-column justify-content-center'>
                <div className='d-flex justify-content-between p-3 align-items-center' style={{ backgroundColor: "#274472", color: "white" }}>
                    <div></div>
                    <span style={{ lineHeight: "0", textAlign: "center" }}><h4 className='ms-5 ps-2'>{props.userState?.bu_data?.business_name}</h4></span>
                    <img src={LOGO} alt="" height={60} />
                </div>
                <div className='d-flex justify-content-center my-3'>
                    <img src={SuccessGif} alt="" className='' style={{ objectFit: "contain", maxWidth: "100%", height: 'auto' }} />
                </div>
                <div className='text-success fs-3 text-center'>Congrats! Your order has been successfully placed</div>
                <div className='col-6 col-sm-3 col-md-2 px-3 px-sm-2 mx-auto my-3'>
                    <button className="btn btn-primary primary-background rounded-pill py-2" onClick={onClickNewOrder}>New order</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    userState: state.UserDataReducer
});

export default connect(mapStateToProps)(SuccessOrder);
import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiFillHome } from "react-icons/ai";
import { PiArrowLeftBold } from "react-icons/pi";
import { Link, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import { dataDecrypt, dataEncrypt } from '../app/utils/encryption-helper';
import Collapsible from 'react-collapsible';
import LoginOtp from './LoginOtp';
import { useSelector } from 'react-redux';
import LOGO from './../assets/images/vyapar_logo.svg';
import { ApisService } from '../app/core/apis.service';
import { DownloadInfo } from './download-info';
import { Modal } from 'react-bootstrap';
import APPSTORE_ICON from './../assets/images/app-appstore.png';
import PLAYSTORE_ICON from './../assets/images/app-playstore.png';

export const Stock_Reconciliation = () => {
    const [StockData, setStockData] = useState<any>([]);
    const userData = useSelector((state: any) => state);
    const [backRouteLink, setBackRouteLink] = useState('');
    const [showAppDownload, setShowAppDownload] = useState(false);
    const [showReceivePopup, setShowReceivePopup] = useState(false);
    const [otpReference, setOtpReference] = useState('');

    const routerParams = useParams();
    const created_date: string = ApisService.formatTimestamp(StockData[0]?.created_date);
    console.log(created_date);

    useEffect(() => {
        loadDelivery();
    }, []);

    async function loadDelivery(): Promise<void> {
        try {
            const res = await ApisService.getTransactionData('finInvoiceById', routerParams.id || '');
            const data: any = res.data.data;
            const transactionData = JSON.parse(dataDecrypt(data));
            console.log('transactionData ', transactionData)
            setStockData([...transactionData]);
            if (transactionData[0]?.transactionId) {
                setBackRouteLink(dataEncrypt(JSON.stringify({ id: transactionData[0].transactionId })));
                const preparedReference = dataEncrypt(JSON.stringify({ invoice: transactionData[0].id, id: transactionData[0].transactionId, type: 'stock' })); 
                setOtpReference(preparedReference);
        
            }
        } catch (error) {
            console.error('Error in transaction:', error);
        }
    }

    const Invoicedata = () => {
        const invoicedata = StockData[0]?.invoice_details;
        const invoiceElements = [];
        if (invoicedata) {
            const invoice = invoicedata.bill_no;
            const invoicedeliverydate = ApisService.formatTimestamp(invoicedata?.order_track?.delivery_date_time);
            const actualdeliverydate = invoicedata?.actual_delivery_date_time;
            const resolution = invoicedata?.stock_reconciliation?.status;
            const grn_no = invoicedata?.order_track?.GRN_No;
            const doc_type = invoicedata?.order_track?.delivery_details_arr[0]?.delivery_type;
            const doc_num = invoicedata?.order_track?.delivery_details_arr[0]?.value;
            const recived_by = invoicedata?.stock_received_by;
            const stock_received_status = invoicedata?.stock_received_status;

            if (invoicedata.ref_invoice_to) {
                const invoiceElement = (
                    <div className='mt-2'>
                        <Collapsible trigger={<Card>
                            <Card.Body style={{ lineHeight: '1' }}>
                                <h6 style={{ color: "#274472" }}>Invoice #{invoice}</h6>
                                <b><span style={{ color: "#FF0000" }}>Invoice Cancelled</span></b>
                            </Card.Body>
                        </Card>}>
                            <Card>
                                <Card.Body  >
                                    <div style={{ lineHeight: '1', color: "#274472" }} >
                                        <div className='row pt-2' >
                                            <b className='col-6'>Expected Delivery Data/Time</b>
                                            <p className='col-6'>: {invoicedeliverydate}</p>
                                        </div>
                                        <div className='row pt-2' >
                                            <b className='col-6'>Actual Delivery Data</b>
                                            <p className='col-6'>: {actualdeliverydate}</p>
                                        </div>
                                        <div className='row pt-2' >
                                            <b className='col-6'>GRN No</b>
                                            <p className='col-6'>: {grn_no}</p>
                                        </div>
                                        <div className='row pt-2' >
                                            <b className='col-6'>Doc Type</b>
                                            <p className='col-6'>: {doc_type}</p>
                                        </div>
                                        <div className='row pt-2' >
                                            <b className='col-6'>{doc_type} No</b>
                                            <p className='col-6'>: {doc_num}</p>
                                        </div>
                                        <div className='row pt-2' >
                                            <b className='col-6'>Recived By</b>
                                            <p className='col-6'>: {recived_by}</p>
                                        </div>
                                        <div className='row pt-2'>
                                            <b className='col-6'>Stock Received Status</b>
                                            <p className='col-6'>: {stock_received_status}</p>
                                        </div>
                                        <div className='row pt-2'>
                                            <b className='col-8'>
                                                Resolution:   not resolved
                                            </b>
                                            <button className='col-4' onClick={openDownloadPopup}>Go to Reconciliation Discussion</button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Collapsible>

                    </div>

                );
                invoiceElements.push(invoiceElement);
            } else {
                const invoiceElement = (
                    <div className='mt-2'>
                        <Collapsible trigger={<Card>
                            <Card.Body style={{ lineHeight: '0.1' }}>
                                <h6 style={{ color: "#274472" }}>Invoice #{invoice}</h6>
                                <p >Resolution : <span >{resolution}</span></p>
                            </Card.Body>
                        </Card>}>
                            <Card>
                                <Card.Body  >
                                    <div style={{ lineHeight: '1', color: "#274472" }} >
                                        <div className='row mb-3' >
                                            <b className='col-6'>Expected Delivery Data/Time</b>
                                            <p className='col-6 mb-0'>{invoicedeliverydate}</p>
                                        </div>
                                        <div className='row mb-3' >
                                            <b className='col-6'>Actual Delivery Data</b>
                                            <p className='col-6 mb-0'>{actualdeliverydate}</p>
                                        </div>
                                        <div className='row mb-3' >
                                            <b className='col-6'>GRN No</b>
                                            <p className='col-6 mb-0'>{grn_no}</p>
                                        </div>
                                        <div className='row mb-3' >
                                            <b className='col-6'>Doc Type</b>
                                            <p className='col-6 mb-0'>{doc_type}</p>
                                        </div>
                                        {doc_type && <div className='row mb-3' >
                                            <b className='col-6'>{doc_type} No</b>
                                            <p className='col-6 mb-0'>{doc_num}</p>
                                        </div>}
                                        <div className='row mb-3' >
                                            <b className='col-6'>Recived By</b>
                                            <p className='col-6 mb-0'>{recived_by}</p>
                                        </div>
                                        <div className='row mb-3'>
                                            <b className='col-6'>Stock Received Status</b>
                                            <p className='col-6 mb-0'>{stock_received_status}</p>
                                        </div>
                                        <div className='row mb-3'>
                                            <b className='col-6'>
                                                Resolution:
                                            </b>
                                            <p className='col-6 mb-0'> {resolution}</p>
                                            {/* <button className='p-2 col-4' style={{ backgroundColor: "#274472", color: "white", border: "none" }}>Go to Reconciliation Discussion</button> */}
                                        </div>
                                        <div className="row">
                                            <div className="col-6 text-end">
                                                <button style={{ backgroundColor: "#274472", color: "white", border: "none" }} onClick={openDownloadPopup}>Go to Reconciliation Discussion</button>
                                            </div>
                                            <div className="col-6">
                                                {stock_received_status !== 'received' && <button style={{ backgroundColor: "#274472", color: "white", border: "none" }} onClick={onStockReceived}>Stock Received</button>}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Collapsible>
                    </div>
                );
                invoiceElements.push(invoiceElement);
            }

        }

        return <div>{invoiceElements}</div>;
    }

    const onStockReceived = async () => {
        console.log('stock received');
        console.log('stock data ', StockData);
        const invoiceDataRec = StockData[0];
        if (invoiceDataRec) {
            const reqBody = {
                txnId: invoiceDataRec.transactionId,
                bill_no : invoiceDataRec.invoice_number,
                stock_received_status: 'received',
                received_type : 'Salable Products'
            }
            const stockReceieveRes = await ApisService.getTransactionData('updateStockReceiveByInvoiceNo', dataEncrypt(JSON.stringify(reqBody)));
            const stockReceieveResData = dataDecrypt(stockReceieveRes.data.data);
            if (stockReceieveResData == '"updated"') {
                setShowReceivePopup(true);
            }
            console.log('stockReceieveResData ',stockReceieveResData == "updated", stockReceieveResData, typeof stockReceieveResData);

        }
    }

    const openDownloadPopup = () => {
        console.log('hello');
        setShowAppDownload(true);
    }
    return (
        <div>
            <DownloadInfo show={showAppDownload} close={setShowAppDownload}></DownloadInfo>
            {(!userData || !userData?.UserDataReducer || !userData?.UserDataReducer?.otp) && <LoginOtp reference={otpReference} />}
            {userData?.UserDataReducer?.otp && <>
                <div className='d-flex justify-content-between p-3' style={{ backgroundColor: "#274472", color: "white" }}>
                    <Link to={`/order/${backRouteLink}`}> <span ><PiArrowLeftBold size={22} className='text-white' /></span></Link>
                    <span style={{ lineHeight: "0", textAlign: "center" }}><h4>Stock Reconciliation</h4><div>{StockData[0]?.buyer?.name}</div></span>
                    <img src={LOGO} alt="" height={60} />
                </div>
                <br />
                <div className='container-fluid'>
                    <div className='rounded'>
                        <div className='card mb-3 p-2'>
                            <div className='card.body'>
                                <div className='row'>
                                    <h6 className='col-4'>Name</h6>
                                    <span className='col-8'>: {StockData[0]?.seller?.name}(Seller)</span>
                                </div>

                                <div className='row'>
                                    <h6 className='col-4'>Txn Id</h6>
                                    <span className='col-8'>: {StockData[0]?.id}</span>
                                </div>

                                <div className='row'>
                                    <h6 className='col-4'>Invoice Number</h6>
                                    <span className='col-8'>: {StockData[0]?.invoice_number}</span>
                                </div>
                                <div className="row">
                                    <h6 className='col-4'>Reconciliation status</h6>
                                    <span className='col-8'>: {StockData[0]?.invoice_details?.stock_received_status}</span>

                                </div>
                            </div>
                        </div>
                    </div>


                    {Invoicedata()}
                </div>
            </>}
            <Modal show={showReceivePopup} onHide={() => setShowReceivePopup(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Stock received successfully</Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-5 text-center'>
                    <h6 className=''>
                        Thank you for the update
                    </h6>
                    <small>
                    To get benefits of these features to enhance your business efficiency subscribe to Vyapar Live application today
                    </small>
                </Modal.Body>
                <Modal.Footer>
                <div className="row">
                <div className="col-md-6 text-center my-4 text-lg-end">
                    <a href="https://play.google.com/store/apps/details?id=com.mr.vyaparlive" target='_blank'>
                        <img src={PLAYSTORE_ICON} alt="" style={{maxWidth: '100%'}}/>
                    </a>
                </div>
                <div className="col-md-6 text-center my-4 text-lg-start">
                    {/* https://apps.apple.com/app/my-awesome-app/id123456789 */}
                    <a href="https://apps.apple.com/app/com.mr.vyaparlive" target='_blank'>
                        <img src={APPSTORE_ICON} alt="" style={{maxWidth: '100%'}}/>
                    </a>
                </div>
            </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
import CryptoJS from 'crypto-js';

import { ENCRYPTION_KEY, IV_KEY } from "../../config";

export const dataDecrypt = (data: string): string => {
    const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
    const iv = CryptoJS.enc.Utf8.parse(IV_KEY);
    const encryptedData = CryptoJS.enc.Hex.parse(data);
    const cipherParams: any = {
        ciphertext: encryptedData,
    };

    const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
};

export const dataEncrypt = (data: string): string => {
    const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
    const iv = CryptoJS.enc.Utf8.parse(IV_KEY);
    var ciphertext = CryptoJS.AES.encrypt(data, key, {iv}).ciphertext.toString();
    return ciphertext;
};
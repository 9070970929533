import React, { useEffect, useRef, useState } from 'react'
import { ApisService } from '../app/core/apis.service';
import { useParams } from 'react-router-dom';
import { dataDecrypt, dataEncrypt } from '../app/utils/encryption-helper';
import LOGO from './../assets/images/vyapar_logo.png';

interface DeleteAccountRequestData {
    contact: string;
    name: string;
}

enum AccountDeleteStatus {
    SUCCESS = 1,
    FAILURE
}

export const DeleteAccount = () => {
    const routerParams = useParams();
    const [deleteRequestData, setDeleteRequestData] = useState<DeleteAccountRequestData>();
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [accountDeleteStatus, setAccountDeleteSuccess] = useState(0);
    const [accountDeleteMessage, setAccountDeleteMessage] = useState('');
    const contactInput = useRef<HTMLInputElement>(null);
    const otpInput = useRef<HTMLInputElement>(null);

    let hasUserData = false;

    useEffect(() => {
        console.log('delete account', routerParams);
        if (!hasUserData) {
            loadUserData();
            hasUserData = true;
        }
    }, []);

    const loadUserData = async () => {
        const resData = await ApisService.getUserData('requestAccountDelete', routerParams.userdata as string);
        if (resData.data) {
            const deleteableRes = JSON.parse(dataDecrypt(resData.data.data));
            setDeleteRequestData(deleteableRes);
        }
    }

    const onRequestOTP = async () => {
        const reqData = {
            contact: contactInput.current?.value,
            type: 'account-delete'
        }
        await ApisService.getUserData('sendUserOTP', dataEncrypt(JSON.stringify(reqData)));
        setIsOtpSent(true);
    }

    const onConfirmDelete = async () => {
        const reqData = {
            contact: contactInput.current?.value,
            otp: Number(otpInput.current?.value)
        }
        setAccountDeleteSuccess(0);
        try {
            const resData = await ApisService.getUserData('confirmUserDeleteWithOTP', dataEncrypt(JSON.stringify(reqData)));
            if (resData.data) {
                const deleteRes = JSON.parse(dataDecrypt(resData.data.data));
                console.log('deleteRes', deleteRes);

                if (deleteRes.error || deleteRes.exception) {
                    setAccountDeleteMessage(deleteRes.message);
                    setAccountDeleteSuccess(2);
                    setAccountDeleteSuccess(AccountDeleteStatus.FAILURE);
                } else {
                    setAccountDeleteSuccess(AccountDeleteStatus.SUCCESS);
                }
            }
        } catch (error) {

        }
    }

    return (
        <div className="">
            <div className='d-flex justify-content-between p-3 align-items-center' style={{ backgroundColor: "#274472", color: "white" }}>
                <span style={{ lineHeight: "0", textAlign: "center" }}><h4>Account Delete</h4></span>
                <img src={LOGO} alt="" height={60} />
            </div>
            <div className="container py-5 px-lg-5">
                <div className="d-flex justify-content-center border shadow py-5 p-3">
                    {
                        accountDeleteStatus === AccountDeleteStatus.SUCCESS &&
                        <h4 className="text-success">Account deleted successfully.</h4>
                    }
                    {
                        accountDeleteStatus !== AccountDeleteStatus.SUCCESS && <div>
                            <div className="form-group mb-2">
                                <label htmlFor="">Please enter registered contact number: </label>
                                <input type="text" ref={contactInput} className='form-control' placeholder={deleteRequestData?.contact || 'e.g 9876543210'} />
                            </div>
                            {isOtpSent && <div className="form-group mb-2">
                                <label htmlFor="">OTP: </label>
                                <input type="text" ref={otpInput} className='form-control' placeholder='4 digit otp' />
                            </div>}
                            {accountDeleteStatus === AccountDeleteStatus.FAILURE && <span className='text-danger'>{accountDeleteMessage}</span>}
                            {!isOtpSent && <button className="btn btn-primary mt-3" onClick={onRequestOTP}>Get OTP</button>}
                            {isOtpSent &&
                                <div className='d-flex'>
                                    <button className="me-3 btn btn-secondary mt-3" onClick={onRequestOTP}>Resend OTP</button>
                                    <button className="btn btn-primary mt-3" onClick={onConfirmDelete}>Confirm Delete</button>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

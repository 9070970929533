import { CaseReducer, createSlice } from "@reduxjs/toolkit";
import { UserData } from "./model/userdata";

export const UserDataSlice = createSlice({
    name: 'UserDataSlice',
    initialState: {
        otp: null
    },
    reducers: {
        otp: (state, action) => {
            console.log('state ', state, action);
            state.otp = action.payload;
        }
    }
});

// export default UserDataSlice.reducer;
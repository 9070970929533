import React, { useState, useRef, ChangeEvent, KeyboardEvent, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import OtpImage from '../assets/images/otp.jpg'
import LOGO_PNG from '../assets/images/vyapar_logo.png';

import { UserDataSlice } from '../app/store/userdata.slice';
import { useDispatch } from 'react-redux';
import { ApisService } from '../app/core/apis.service';
interface Props {
  reference: string;
  transactionId?: string;
}

const LoginOtp = (props: Props) => {
  const [otpInput, setOtpInput] = useState('');
  const dispatch = useDispatch();
  let isOTPRequested = false;

  useEffect(() => {
    if (!isOTPRequested && props.reference) {
      requestOTP();
    }
  }, [props.reference]);

  const handleInputChange = (value: string) => {
    if (isNaN(Number(value))) {
      return;
    }
    setOtpInput(value);
  };

  const onVerifyOTP = async () => {
    console.log('input ', otpInput);
    const verifyRes = await ApisService.verifyOTP(Number(otpInput), props?.reference as string);
    console.log('rerify res', verifyRes.data);
    if (verifyRes.data) {
      dispatch(UserDataSlice.actions.otp(otpInput));
    }
  }

  const skipOTPVerification = async () => {
    dispatch(UserDataSlice.actions.otp(11));
  }

  const requestOTP = async () => {
    console.log('request otp', props.reference);
    const otpRequest = await ApisService.getTransactionData('sendOfflineCustomerOTP', props.reference);
    console.log('otp request ', otpRequest);
    isOTPRequested = true;
  }

  return (
    <div className="">
      <div className="d-flex justify-content-center ">

        <Card style={{ borderRadius: "10px", borderColor: "smokeWhite" }} className="otp-card p-3 mx-5 shadow-lg p-3 my-2 bg-body rounded">
          <div className="text-center">
            <img src={LOGO_PNG} style={{ maxWidth: '100%', height: 200 }} alt="OTP Icon" />
          </div>
          <p className="text-center" style={{ color: '#ff9800' }}>
            Your one stop solution for business operations
          </p>
          <div className="text-center">
            <h6 className="otp-header mb-3">Enter OTP Code</h6>
            <div className="otp-input-field mb-3">
              <input className='form-control' type="text" placeholder='xxxxxx' value={otpInput} name="" id="" onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.value)} />
            </div>
            <button className="text-center btn btn-primary otp border-0 mb-3" onClick={onVerifyOTP}>Verify OTP </button>
            <p className="text-center text-primary otp border-0" onClick={skipOTPVerification}>Skip OTP for now</p>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default LoginOtp;

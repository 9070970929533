import { BU_DETAILS } from "./action-types";

interface UserData {
    otp: string;
    bu_data: any;
}
const initialState: UserData = {
    otp: '',
    bu_data: null
};
export const UserDataReducer = (state: UserData = initialState, action: any) => {
    console.log('action ', action);

    switch (action.type) {
        case 'otp':
            return { ...state, otp: action.data };

            break;

        case BU_DETAILS:
            return { ...state, bu_data: action.data };
            break;
        default:
            return state;
            break;
    }
}
import { Card } from 'react-bootstrap';
import LOGO from './../assets/images/vyapar_logo.svg';
import { PiArrowLeftBold } from "react-icons/pi";
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ApisService } from '../app/core/apis.service';
import { dataDecrypt, dataEncrypt } from '../app/utils/encryption-helper';

export const TransactionDelivery = () => {
    const routerParams = useParams();
    const [transactionData, setTransactionData] = useState<any>([]);

    useEffect(() => {
        loadTransaction();
    }, []);

    const loadTransaction = async (): Promise<void> => {
        console.log('routerParams ', routerParams);

        try {
            const res = await ApisService.getTransactionData('getById_v2', routerParams.id || '');
            const data: any = res.data.data;
            const transactionData = JSON.parse(dataDecrypt(data));
            if(transactionData[0].invoice_details) {
                for (let index = 0; index < transactionData[0].invoice_details.length; index++) {
                    const element = transactionData[0].invoice_details[index];
                    element.encrypted_id = dataEncrypt(`{"id":"${element.invoice_id}"}`);
                }
            }
            setTransactionData([...transactionData]);
            console.log('transaction data ', transactionData);
            

        } catch (error) {
            console.error('Error in transaction:', error);
        }
    }
    return <div>
        {/* <div className='d-flex justify-content-center  p-3 position-relative' style={{ backgroundColor: "#274472", color: "white" }}>
            <h4>Packing Details</h4>
            <img className='position-absolute end-0 top-0' src={LOGO} alt="" height={60} />
        </div> */}
        <div className='d-flex justify-content-between p-3 align-items-center' style={{ backgroundColor: "#274472", color: "white" }}>
          <Link to={`/order/${routerParams.id}`}><span ><PiArrowLeftBold size={22} className='text-white' /></span></Link>
          <span style={{ lineHeight: "0", textAlign: "center" }}><h4>Select Invoice</h4><div>{transactionData[0]?.buyer.name}</div></span>
          <img src={LOGO} alt="" height={60} />
        </div>
        <br></br>
        {transactionData[0] && <div className="container-fluid">
            <div style={{ backgroundColor: "white", borderRadius: "3px" }}>
                <Card>
                    <Card.Body>
                        <div className='row'>
                            <h6 className='col-4 '>Name</h6>
                            <span className='col-8 '>: {transactionData[0]?.seller?.name}  (Seller)</span>

                        </div>
                        <div className='row'>
                            <h6 className='col-4'>Txn Initiated Date</h6>
                            <span className='col-8'>: {ApisService.formatTimestamp(transactionData[0].created_date)}</span>
                        </div>
                    </Card.Body>
                </Card>
                <br></br>
                <Card>
                    <Card.Body>
                        {
                            transactionData[0].invoice_details?.map((el: any)=>
                                <Link to={`/delivery/${el.encrypted_id}`}>
                                    Invoice Number: {el.bill_no}
                                </Link>
                            )
                        }
                    </Card.Body>
                </Card>
            </div>
        </div>}
        <br></br>
    </div>
}
import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiFillHome } from "react-icons/ai";
import { PiArrowLeftBold } from "react-icons/pi";
import { Link, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import { dataDecrypt, dataEncrypt } from '../app/utils/encryption-helper';
import Collapsible from 'react-collapsible';
import { useSelector } from 'react-redux';
import LoginOtp from './LoginOtp';
import LOGO from './../assets/images/vyapar_logo.png';
import { ApisService } from '../app/core/apis.service';
import { AppConfig } from '../app/config';




export const Invoice = () => {
  const [invoiceData, setinvoiceData] = useState<any>([]);
  const [isOtpEntered, setIsOtpEntered] = useState(false);
  const [otpReference, setOtpReference] = useState('');
  const userData = useSelector((state: any) => state)

  const routerParams = useParams();
  //time 
  function formatTimestamp(timestamp: number): string {
    const dateObj = new Date(timestamp);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const hours = dateObj.getHours();
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
  }
  const created_date: string = formatTimestamp(invoiceData[0]?.created_date);
  console.log(created_date);


  useEffect(() => {
    loadInvoice();
  }, []);

  useEffect(() => {
    if (userData?.UserDataReducer?.otp) {
      setIsOtpEntered(true);
    }
  }, [userData])

  async function loadInvoice(): Promise<void> {
    console.log('routerParams ', routerParams);

    try {
      const res = await ApisService.getTransactionData('getById_v2', routerParams.id || '');
      const data: any = res.data.data;
      const transactionData = JSON.parse(dataDecrypt(data));
      setinvoiceData([...transactionData]);
      const invoiceId = JSON.parse( dataDecrypt(routerParams.id as string));
      if (transactionData[0]) {
        const preparedReference = dataEncrypt(JSON.stringify({ invoice: invoiceId?.id, id: transactionData[0].id, type: 'invoice' })); 
        setOtpReference(preparedReference);
      }

    } catch (error) {
      console.error('Error in transaction:', error);
    }
  }
  const Invoicedata = () => {
    const invoiceDetails = invoiceData[0]?.invoice_details;
    const invoiceElements = [];
    for (let i = 0; i < invoiceDetails?.length; i++) {
      const invoicedata = invoiceDetails[i];
      const invoice = invoicedata.bill_no;
      const invoicedate = formatTimestamp(invoicedata?.invoice_date);
      const invoicestatus = invoicedata.status;
      if (invoicedata.ref_invoice_to) {
        const invoiceElement = (
          <div key={i} className='mt-2'>
            <Collapsible trigger={<Card>
              <Card.Body style={{ lineHeight: '0.1' }}>
                <p style={{ color: "#274472" }}>Invoice #{invoice}</p>
                <p>{invoicedate}</p>
                <p>Status:<span style={{ color: "#FF0000" }}>{invoicestatus}</span></p>
              </Card.Body>
            </Card>}>

              <Card>
                <Card.Body style={{ lineHeight: '0.1' }} ><p style={{ color: "#274472" }}>Invoice #{invoicedata.ref_invoice_to.bill_no}</p>
                  <div className='d-flex justify-content-between'>
                    <p >{formatTimestamp(invoicedata.ref_invoice_to.invoice_date)}</p>
                    <p style={{ color: "#274472" }}>{invoicedata.ref_invoice_to.status}</p>
                  </div>
                </Card.Body>
              </Card>
            </Collapsible>

          </div>

        );
        invoiceElements.push(invoiceElement);
      }
      else {
        const invoiceElement = (
          <div key={i} className='mt-2'>
            <Card>
              <Card.Body style={{ lineHeight: '0.1' }}>
                <a target='_blank' href={`${AppConfig.BASE_URL}/pdfs/${invoiceData[0]?.id}/invoice-pdf/${invoice}`}>
                  <p style={{ color: "#274472" }}>Invoice #{invoice}</p>
                </a>
                <p>{invoicedate}</p>
                <p>Status: <span style={{ color: "#274472", textTransform: 'capitalize' }}>{invoicestatus}</span></p>
              </Card.Body>
            </Card>
          </div>
        );
        invoiceElements.push(invoiceElement);
      }
    }
    return <div>{invoiceElements}</div>;
  }
  return (
    <div>
      {!isOtpEntered && <LoginOtp reference= {otpReference} transactionId={invoiceData[0]?.id} />}
      {isOtpEntered && <>
        <div className='d-flex justify-content-between p-3 align-items-center' style={{ backgroundColor: "#274472", color: "white" }}>
          <Link to={`/order/${routerParams.id}`}><span ><PiArrowLeftBold size={22} className='text-white' /></span></Link>
          <span style={{ lineHeight: "0", textAlign: "center" }}><h4>Invoice List</h4><div>{invoiceData[0]?.buyer.name}</div></span>
          <img src={LOGO} alt="" height={60} />


        </div>
        <br />
        <div className='container-fluid'>
          <div style={{ backgroundColor: "white", borderRadius: "3px" }} className='mb-4'>
            <Card>
              <Card.Body>
                <div className='row'>
                  <h6 className='col-4'>Name</h6>
                  <span className='col-8'>: {invoiceData[0]?.seller?.name}(Seller)</span>

                </div>

                <div className='row'>
                  <h6 className='col-4'>Txn Initiated Date</h6>
                  <div className='col-8'>: {created_date}</div>

                </div>

              </Card.Body>
            </Card>
          </div>

          {Invoicedata()}

        </div>
      </>}
    </div>

  )
}

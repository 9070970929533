import React, { ChangeEvent, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { PiArrowLeftBold } from "react-icons/pi";
import { PiTrashBold } from "react-icons/pi";
import LOGO from './../assets/images/vyapar_logo.png';
import { ApisService } from '../app/core/apis.service';
import { dataDecrypt, dataEncrypt } from '../app/utils/encryption-helper';
import { AppConfig } from '../app/config';
import NoProductImage from './../assets/images/no_product_image.png';
import NordersImage from './../assets/images/no_orders_found.jpg';
import { Dispatch } from 'redux';
import { BU_DETAILS } from '../app/store/action-types';
import { connect } from 'react-redux';

interface Props {
    updateBuDetails?: (data: any)=>void;
}

const OrderFormListPage = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [productsData, setProductsData] = useState<any>([]);
    const [selectedProducts, setSelectedProducts] = useState<any>([]);
    const [showUserDetailsForm, setShowUserDetailsForm] = useState<boolean>(false);
    const [showPlaceOrderButtonText, setShowPlaceOrderButtonText] = useState<boolean>(false);
    const [showUSerDetailsData, setShowUSerDetailsData] = useState<boolean>(false);
    const [buDetailsData, setBuDetailsData] = useState<any>([])
    const [buAddressData, setBuAddressData] = useState<any>({});
    // const [buttonStatus, setButtonStatus] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<any>([]);
    const navigation = useNavigate();
    const mobileNumberRegex = /^[6-9]\d{9}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-z]+.[a-z]{2,6}$/;
    const [userDetailsData, setUserDetailsData] = useState<any>({
        name: "",
        contact_number: "",
        email: "",
        address: "",
    })
    const [productErrors, setProductErrors] = useState<any>({});
    const routerParams = useParams();

    useEffect(() => {
        loadProducts();
        loadBuDetails();
    }, [])

    async function loadProducts(): Promise<void> {
        setLoading(true)
        try {
            const res = await ApisService.getProductData('findProductList_v2', routerParams.id || '');
            const data: any = res.data.data;
            const productData = JSON.parse(dataDecrypt(data));
            const filterProductsData = productData?.results?.filter((el: any) => el?.for_sale === true && el?.in_stock === true);
            setProductsData(filterProductsData)
            setLoading(false)
        } catch (error) {
            console.error('Error in products:', error);
            setLoading(false)
        }
    }

    async function loadBuDetails(): Promise<void> {
        setLoading(true)
        try {
            // const id = "908b88a1939383024c36570992489ee3be209a4f0a0d11da4068c8c956c8a41b18fecc3d084e6c0d12c4cfe532d50562";
            const res = await ApisService.getBuDetailsData('findById', routerParams.id || '');
            const data: any = res.data.data;
            const buData = JSON.parse(dataDecrypt(data));
            setBuDetailsData(buData[0])
            setBuAddressData(buData[0]?.contact_details?.address)
            setLoading(false);
            console.log('props.', props.updateBuDetails);
            
            if(props.updateBuDetails) {
                props.updateBuDetails(buData[0]);
            }
        } catch (error) {
            console.error('Error in products:', error);
            setLoading(false)
        }
    }

    const formatCurrency = (amount: number) => {
        const formattedAmount = amount?.toLocaleString('en-IN', { maximumFractionDigits: 2 });
        if (amount > 10000) {
            return `₹ ${formattedAmount}`;
        } else if (amount === undefined) {
            return "-";
        } else {
            return `₹ ${formattedAmount}`;
        }
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>, product: any, index: number) => {
        const quantity = parseInt(e.target.value, 10);
        if (quantity < 0) {
            setProductErrors((prevErrors: any) => ({
                ...prevErrors,
                [product.id]: "Quantity cannot be negative",
            }));
            return;
        }
        setProductErrors((prevErrors: any) => ({
            ...prevErrors,
            [product.id]: undefined,
        }));

        const updatedSelectedProducts = [...selectedProducts];
        const existingProductIndex = selectedProducts.findIndex((p: any) => p.id === product.id);

        if (quantity > 0) {
            if (existingProductIndex !== -1) {
                updatedSelectedProducts[existingProductIndex].quantity = quantity;
            } else {
                updatedSelectedProducts.push({ ...product, quantity });
            }
        } else {
            if (existingProductIndex !== -1) {
                updatedSelectedProducts.splice(existingProductIndex, 1);
            }
        }
        console.table(updatedSelectedProducts, ['name', '_id', 'quantity']);
        
        setSelectedProducts(updatedSelectedProducts);
        updateSelectedProductsInfo(updatedSelectedProducts);
    };

    const updateSelectedProductsInfo = (updatedSelectedProducts: any[]) => {
        const summaryInfo = updatedSelectedProducts.map(product => {
            if (product && product.batch_pricing && product.batch_pricing[0] && typeof product.batch_pricing[0].price === 'number') {
                return {
                    name: product.name,
                    quantity: product.quantity || 0,
                    price: product.batch_pricing[0].price,
                    total: (product.quantity || 0) * product.batch_pricing[0].price
                };
            } else {
                return {
                    name: "",
                    quantity: 0,
                    price: 0,
                    total: 0
                };
            }
        });
    };

    const calculateTotalAmount = () => {
        return selectedProducts.reduce((total: any, product: any) => {
            return total + product.quantity * product.batch_pricing[0]?.price;
        }, 0);
    };

    const removeProduct = (product: any) => {
        for (let i = 0; i < productsData?.length; i++) {
            if (productsData[i]?.id === product?.id) {
                productsData[i].quantity = 0;
                productsData[i].removed_product = true
                setProductsData([...productsData])
            }
        }
        const updatedProductsData = productsData.map((p: any) => {
            if (p.id === product.id) {
                return {
                    ...p,
                    quantity: 0,
                    removed_product: true
                };
            }
            return p;
        });
        setProductsData(updatedProductsData)
        const updatedSelectedProducts = selectedProducts.filter((p: any) => p.id !== product.id);
        setSelectedProducts(updatedSelectedProducts);
        updateSelectedProductsInfo(updatedSelectedProducts);
    };

    const onPlaceYourOrder = async () => {
        const placedOrderItems: any = [];
        selectedProducts?.forEach((el: any) => {
            placedOrderItems.push({
                product_id: el?.id,
                quantity: el?.quantity
            });
        });
        const {building_no, buildingstreetname, landmark, state, villagetowncity} = userDetailsData;
        const preparedAddress = {
            building_no, buildingstreetname, landmark, state, villagetowncity
        }
        userDetailsData.address = preparedAddress
        userDetailsData.ship_to_address = preparedAddress;
        console.log('placedOrderItems ', placedOrderItems, userDetailsData, buDetailsData);
        const orderData = {
            bu: buDetailsData._id,
            buyer: userDetailsData,
            products: placedOrderItems
        };
        console.log('orderData ', dataEncrypt(JSON.stringify(orderData)));

        await ApisService.getTransactionData('createBUOrderFormTransaction', dataEncrypt(JSON.stringify(orderData)));
        setShowUserDetailsForm(false);
        navigation(`/success-order?bu=${buDetailsData?.business_name}`)
        // setButtonStatus(true);
    }

    const onClickSubmit = () => {
        const isValid: any = isUserDetailsValid();
        console.log('isvalid ', isValid);

        if (isValid) {
            setShowUSerDetailsData(true);
            setShowUserDetailsForm(false);
            setShowPlaceOrderButtonText(true);
        } else {
            setShowPlaceOrderButtonText(false);
        }
    }

    const onClickBackToProductsList = () => {
        setShowUserDetailsForm(false);
        setShowUSerDetailsData(false)
    }

    const isUserDetailsValid = () => {
        const errors: any = {};
        if (!userDetailsData.name) {
            errors.name = "Please enter your name";
        }
        if (!userDetailsData.contact_number) {
            errors.contact_number = "Please enter your mobile number";
        } else if (!mobileNumberRegex.test(userDetailsData.contact_number)) {
            errors.contact_number = "Please enter valid mobile number"
        }
        if (!userDetailsData.email) {
            errors.email = "Please enter your email";
        } else if (!emailRegex.test(userDetailsData.email)) {
            errors.email = "Please enter valid email"
        }
        setFormErrors(errors);
        console.log('errors ', errors);
        
        return Object.keys(errors).length === 0;
    };

    const handleInput = (e: any) => {
        const { name, value } = e.target;
        setUserDetailsData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
        setFormErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: validateSpecificFeild(name, value),
        }));
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = e;
        // Allow only numeric characters (0-9) and specific control keys
        if (!/^\d$/.test(key) && !['Tab', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(key)) {
            e.preventDefault();
        }
    };

    const validateSpecificFeild = (name: any, value: any) => {
        if (name === "contact_number") {
            if (!value) {
                return "Please enter your mobile number";
            } else if (!mobileNumberRegex.test(value)) {
                return "Please enter valid mobile number";
            }
        } else if (name === "email") {
            if (!value) {
                return "Please enter your email";
            } else if (!emailRegex.test(value)) {
                return "Please enter valid email ";
            }
        }
        return "";
    }

    const onClickContinue = () => {
        setShowUserDetailsForm(true);
    };

    return (
        <div>
            <div className='d-flex justify-content-between p-3 align-items-center' style={{ backgroundColor: "#274472", color: "white" }}>
                {/* <Link to={`/order-form/dsd`}><span><PiArrowLeftBold size={22} className='text-white' /></span></Link> */}
                <span style={{ lineHeight: "0", textAlign: "center" }}><h4>{buDetailsData?.business_name}</h4></span>
                <img src={LOGO} alt="" height={60} />
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-7 col-lg-8'>
                        {loading &&
                            <div className="text-center p-5">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                        }
                        {!loading && (
                            <div className='mb-3'>
                                {!showUSerDetailsData && !showUserDetailsForm && (<>
                                    <div className='my-2 ms-2 fw-bold'>Place your order</div>
                                    <div style={{ height: "500px", overflowY: "auto" }}>
                                        {productsData?.length > 0 && productsData?.map((product: any, index: number) => (
                                            <div className={`card my-2 primary-border mx-0 mx-2`} key={index}>
                                                <div className='d-sm-flex justify-content-between mx-3'>
                                                    <div className='p-0 p-xs-2 d-sm-flex justify-content-start'>
                                                        <div className='text-center text-sm-start'>
                                                            {product?.images && <img src={`${AppConfig?.IMAGE_PATH}${product?.images}`} className='product-image' />}
                                                            {!product?.images && <img src={NoProductImage} className='product-image' />}
                                                        </div>
                                                        <div className='mx-1 mx-sm-3 align-items-center my-md-1'>
                                                            <div className='primary-color fw-bold'> {product?.name ? product?.name : "-"}</div>
                                                            <div><span className='fw-bold'>Brand </span> : {product?.brand ? product?.brand : "-"}</div>
                                                            <div><span className='fw-bold'>Price </span>: {product?.batch_pricing[0]?.price ? product?.batch_pricing[0]?.price : "-"}</div>
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <div className='my-2'>
                                                            <label className='form-label'>Quantity</label>
                                                            <input type="number" name="quantity" className='form-control border-dark' onChange={(e) => handleInputChange(e, product, index)}
                                                                value={selectedProducts.find((p: any) => p.id === product.id)?.quantity || "0"}
                                                            />
                                                            {productErrors[product.id] && (
                                                                <div className="text-danger">{productErrors[product.id]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {productsData?.length === 0 && (
                                            <div className='d-flex flex-column justify-content-center mt-5 pt-5 align-items-center'>
                                                <div>
                                                    <img src={NordersImage} alt="" className='' />
                                                </div>
                                                <div className='text-center'>No Products Found</div>
                                            </div>
                                        )}
                                    </div>
                                </>)}
                                {showUserDetailsForm && (
                                    <div >
                                        <div className='my-3 ms-2 fw-bold'>Please enter your details</div>
                                        <div className='mx-2 px-3 mx-md-auto col-11 col-md-8 col-lg-6 rounded-2 p-2'>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                                                <input type="text" name="name" className="form-control" id="exampleFormControlInput1" defaultValue={userDetailsData?.name} onChange={handleInput} placeholder="Please enter your name" />
                                                {formErrors.name && (<div className="text-danger text-start w-100">{formErrors.name}</div>)}
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Mobile Number</label>
                                                <input type="text" maxLength={10} name="contact_number" className="form-control number-only" id="exampleFormControlInput1" onKeyDown={handleKeyDown} defaultValue={userDetailsData?.contact_number} onChange={handleInput} placeholder="Please enter your mobile number" />
                                                {formErrors.contact_number && (<div className="text-danger text-start w-100">{formErrors.contact_number}</div>)}
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Email</label>
                                                <input type="email" name="email" className="form-control" id="exampleFormControlInput1" defaultValue={userDetailsData?.email} onChange={handleInput} placeholder="Please enter your email" />
                                                {formErrors.email && (<div className="text-danger text-start w-100">{formErrors.email}</div>)}
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlTextarea1" className="form-label">Address</label>
                                                <div className="border p-3">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="">Building</label>
                                                        <input type="text" name="building_no" id="" className='form-control' onChange={handleInput} />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="">Street Address</label>
                                                        <input type="text" name="buildingstreetname" id="" className='form-control' onChange={handleInput} />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="">Locality/Landmark</label>
                                                        <input type="text" name="landmark" id="" className='form-control' onChange={handleInput} />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="">City</label>
                                                        <input type="text" name="villagetowncity" id="" className='form-control' onChange={handleInput} />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="">State</label>
                                                        <input type="text" name="state" id="" className='form-control' onChange={handleInput} />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="">Pincode</label>
                                                        <input type="text" name="state" id="" className='form-control' onChange={handleInput} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='text-center mx-auto'>
                                                <button className='btn btn-primary mx-auto cursor-pointer py-2 primary-background mx-3 rounded-pill w-50' onClick={onClickSubmit}>Submit</button>
                                                <button className='cursor-pointer bg-transparent rounded-0 border-none primary-color' onClick={onClickBackToProductsList}>Back to products list</button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {showUSerDetailsData && (
                                    <div>
                                        <div className='card rounded-2 primary-border my-3'>
                                            <div className='my-2 ms-3 fw-bold '>BU(Business Unit) details</div>
                                            <div className="mx-3">
                                                <div className='primary-color fw-bold'>{buDetailsData?.business_name}</div>
                                                <div className='fs-14'>{buAddressData?.plot_no ? `${buAddressData?.plot_no}, ` : ""}{buAddressData?.buildinghousenumber ? `${buAddressData?.buildinghousenumber},` : ""}</div>
                                                <div className='fs-14'>{buAddressData?.building_no ? `${buAddressData?.building_no}, ` : ""}{buAddressData?.landmark ? `${buAddressData?.landmark}, ` : ""}{buAddressData?.buildingstreetname ? `${buAddressData?.buildingstreetname},` : ""}</div>
                                                <div className='fs-14'>{buAddressData?.villagetowncity ? `${buAddressData?.villagetowncity}, ` : ""}{buAddressData?.taluk}</div>
                                                <div className='fs-14'>{buAddressData?.district ? `${buAddressData?.district}, ` : ""}{buAddressData?.state ? buAddressData?.state : ""} - {buAddressData?.postcode ? buAddressData?.postcode : ""}</div>
                                                <div className='fs-14'>{buAddressData?.country}</div>
                                                <div className='fs-14 mb-2'>Phone No : {buAddressData?.phone_number_on_invoice ? buAddressData?.phone_number_on_invoice : ""} | Email : {buAddressData?.email_on_invoice ? buAddressData?.email_on_invoice : ""}</div>
                                            </div>
                                        </div>
                                        <div className='card rounded-2 primary-border my-3'>
                                            <div className='my-2 ms-3 fw-bold '>Your details</div>
                                            <div className="mx-3">
                                                <div className='fs-14'>{userDetailsData?.user_name}</div>
                                                <div className='fs-14'>{userDetailsData?.contact_number}</div>
                                                <div className='fs-14'>{userDetailsData?.email}</div>
                                                <div className='fs-14 mb-2'>{userDetailsData?.address}</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className='col-md-5 col-lg-4 primary-left-border'>
                        <div className={`my-3 py-2 fw-bold card primary-border ${selectedProducts?.length > 0 ? "d-block" : "d-none"}`}>
                            <div className={`ps-3 ${selectedProducts?.length > 0 ? "d-block" : "d-none"}`}>Order Summary</div>
                            <div>
                                <div className='border-bottom mx-2'>
                                    {selectedProducts?.length > 0 && selectedProducts?.map((product: any, index: number) => (
                                        <div className='' key={index}>
                                            <div className='p-2'>
                                                {/* <div className='mx-1'> */}
                                                    {product?.quantity && (
                                                        <div className='d-flex justify-content-between'>
                                                            <div className='fw-normal'><b>{product?.quantity}</b> x <span className='-nfwone'>{product?.name}</span></div>
                                                            <div className='d-flex justify-content-end mx-2'>
                                                                <div className='fw-normal'>{formatCurrency(product?.quantity * product?.batch_pricing[0]?.price)}</div>
                                                                <div className='ms-auto me-0 my-0 py-0' style={{ width: "10%" }}> <div onClick={() => removeProduct(product)}><PiTrashBold size={20} className='text-danger cursor-pointer' /></div></div>
                                                            </div>
                                                        </div>
                                                    )}

                                                {/* </div> */}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='border-top-1 mx-1'>
                                    {selectedProducts?.length > 0 && (
                                        <div className='my-1'>
                                            <div className='p-2'>
                                                <div className='mx-1'>
                                                    <div className='d-flex justify-content-between'>
                                                        <div className='text-start fs-6 ms-1'>Total</div>
                                                        <div className='text-end me-4 pe-2'>{formatCurrency(calculateTotalAmount())}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {selectedProducts?.length > 0 && (
                            <div className='text-center mb-1'>
                                {!showPlaceOrderButtonText && <button className="btn btn-primary mx-auto cursor-pointer py-2 primary-background mx-3 rounded-pill w-50 mb-2" onClick={onClickContinue}>Continue</button>}
                                {showPlaceOrderButtonText && <button className={`btn btn-primary mx-auto cursor-pointer py-2 primary-background mx-3 rounded-pill w-50 mb-2`} onClick={onPlaceYourOrder}>Place Your Order</button>}
                            </div>
                        )}
                        {selectedProducts?.length === 0 && (
                            <div className='d-flex flex-column justify-content-center my-5 my-md-0 mt-md-5 pt-md-5 align-items-center'>
                                <div className='mt-4 pt-3'>
                                    <img src={NordersImage} alt="" className='' />
                                </div>
                                <div className='text-center'>You have not selected any products</div>
                            </div>)}
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateBuDetails: (data: any) => { dispatch({ type: BU_DETAILS, data }) }
})
export default connect(mapStateToProps, mapDispatchToProps)(OrderFormListPage);